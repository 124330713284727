.App {
  text-align: center;
  /* background-color: #189b18 !important; */

}
input,select,textarea{
  font-size: 12px !important;
  color:black !important;
  /* border:0.2px solid black !important; */
}

input::placeholder{
  font-size: 12px !important;
}
.main_content_iner{
  background-color: #f3f4f3 !important;
}
.table thead tr th{
  color:black !important;
  font-family:Arial;
}
.table tbody tr th a, .table tbody tr td{
  font-size:14px;
  color:black !important;
  font-family:Arial;

}
#example_filter{
  margin-bottom:10px  !important;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  outline: 1px solid slategrey;
  border-radius: 1rem;
}

.text-small {
  font-size: 0.9rem;
}

.messages-box,
.chat-box {
  height: 500px;
  width: 600px !important;
  overflow-y: scroll;
}

.rounded-lg {
  border-radius: 0.5rem;
}

input::placeholder {
  font-size: 0.9rem;
  color: #999;
}
#modal-btn{
  margin-left: 4px; 
}
.icon_menu i,svg{
  color:#64c5a9 !important;
  font-size: 18px !important;
}

#delete {
  margin-left: 4px;
}
#update {
  margin-right: 4px;
}

/* dashboard buttons spacing */
#dashbtn{
  margin-right:8px !important;

}

.hiddenRow {
  padding: 0 !important;
}

.scroll-widget{
	width: 100%;
    height: 400px;
    overflow: auto;
}

.marker .highlight{
  background: white;
}

.main-sidebar{
  z-index: 1 !important;
}
/* import the Mention dependency styles */

/* import the Mention dependency styles */
