body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-signin-wrapper .back-img{
  filter: blur(3px);
 /* background-position: 'center !important'; */
  /* background-image:url('https://cdn.thecoolist.com/wp-content/uploads/2022/01/Types-of-Houses.png'); */
  background-repeat: 'no-repeat !important';
  background-size:'cover !important'; 
}